









































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { Course } from "@/types";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { AuthData } from "@/store/auth/types";

@Component<CourseDetail>({
  metaInfo() {
    return {
      title: this.pageTitle
    };
  },
  components: {
    Breadcrumbs
  }
})
export default class CourseDetail extends Vue {
  course = {} as Course;

  get breadcrumbs(): unknown[] {
    return [
      {
        text: "Ana Sayfa",
        exact: true,
        to: { name: "Home" }
      },
      {
        text: "Eğitim Kataloğu",
        exact: true,
        to: { name: "CourseCatalog" }
      },
      {
        text: this.pageTitle
      }
    ];
  }

  submitDisabled = false;

  get pageTitle(): string {
    return this.course.name;
  }

  get imagePath(): string {
    return `${this.$store.getters["uploadFolder"]}/${this.course.image}`;
  }

  get authData(): AuthData {
    return this.$store.getters["auth/data"];
  }

  get isUserLoggedIn(): boolean {
    return this.$store.getters["auth/isUserLoggedIn"];
  }

  async getCourse(): Promise<void> {
    try {
      const urlCode = this.$route.params.urlCode;
      const authHeader = this.$store.getters["auth/requestAuthHeader"];

      // Get course
      var res = await axios.get(`/api/course/get-course/${urlCode}`, {
        headers: authHeader
      });

      this.course = res.data;

      if (this.course.startDate) this.course.startDate = new Date(this.course.startDate);
      if (this.course.finishDate) this.course.finishDate = new Date(this.course.finishDate);
    } catch (e) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: `Eğitim bilgileri alınamadı.`
      });

      throw e;
    }
  }

  addToCart(): void {
    this.submitDisabled = true;

    try {
      // Kullanıcı yoksa girişe yönlendir
      if (!this.isUserLoggedIn) {
        this.$router.push({
          name: "Login",
          query: {
            returnUrl: this.$route.fullPath
          }
        });

        return;
      }

      this.$store.dispatch("cart/addItem", {
        courseId: this.course.id,
        courseName: this.course.name,
        coursePrice: this.course.price
      });

      this.$store.dispatch("cart/saveCart");

      this.$notify({
        type: "success",
        text: `"${this.course.name}" sepete eklendi.`
      });
    } catch (e) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: `"${this.course.name}" sepete eklenemedi.`
      });
      throw e;
    } finally {
      this.submitDisabled = false;
    }
  }

  async mounted(): Promise<void> {
    await this.getCourse();
  }
}
